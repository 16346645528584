import React, { useState, useEffect } from 'react';

// Configuration for tabs (unchanged)
const tabs = [
  {
    id: 'our-mission',
    label: 'Our Mission',
    content: 'Advancing technology with a thoughtful approach to benefit humanity.'
  },
  {
    id: 'challenges',
    label: 'Challenges',
    content: 'Are you ready to revolutionize the future?',
    challenges: [
      {
        id: 'challenge1',
        name: 'Schrödingers Bot',
        image: '/Quandary.jpg',
        description: 'Create a quantum-inspired, probabilistic chatbot that exists in multiple states simultaneously, mirroring the principles of quantum superposition.'
      },
      {
        id: 'challenge2',
        name: 'Social Mind',
        image: '/Neurosynth.jpg',
        description: 'Develop an AI system that simulates a collective consciousness by aggregating and synthesizing real-time human thoughts and emotions from social media data.'
      },
      {
        id: 'challenge3',
        name: 'Butterfly Effect',
        image: '/Chronosphere.jpg',
        description: 'Create an AI-powered simulation that explores the consequences of time travel paradoxes, allowing users to interact with and manipulate historical timelines.'
      }
    ]
  },
  {
    id: 'contact-us',
    label: 'Contact Us',
    content: null
  }
];

const ChallengeCard = ({ challenge, isOpen, onToggle }) => (
  <div className="bg-sky-900 rounded-lg overflow-hidden shadow-lg transition-all duration-300 transform hover:scale-105">
    <img 
      src={challenge.image} 
      alt={challenge.name} 
      className="w-full h-48 object-cover cursor-pointer" 
      onClick={onToggle}
    />
    <div className="p-4">
      <h3 className="text-xl font-bold mb-2">{challenge.name}</h3>
      {isOpen && (
        <p className="text-gray-300">{challenge.description}</p>
      )}
    </div>
  </div>
);

const ChallengesSection = ({ challenges }) => {
  const [openChallenge, setOpenChallenge] = useState(null);

  const handleToggle = (id) => {
    setOpenChallenge(openChallenge === id ? null : id);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
      {challenges.map((challenge) => (
        <ChallengeCard 
          key={challenge.id}
          challenge={challenge}
          isOpen={openChallenge === challenge.id}
          onToggle={() => handleToggle(challenge.id)}
        />
      ))}
    </div>
  );
};

const useResponsive = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const FuturisticTyping = ({ text, speed = 50, onComplete }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prevText) => {
          const newText = prevText + text[index];
          if (index === text.length - 1) {
            onComplete(newText);
          }
          return newText;
        });
        setIndex((prevIndex) => prevIndex + 1);
      }, speed);
      return () => clearTimeout(timer);
    }
  }, [index, text, speed, onComplete]);

  useEffect(() => {
    setDisplayedText('');
    setIndex(0);
  }, [text]);

  return (
    <div className="relative">
	  	      <style>{globalStyles}</style>
      <span className="text-white font-mono text-lg md:text-xl">
        {displayedText}
      </span>
      <span className="absolute bottom-0 w-2 md:w-3 h-4 md:h-6 bg-white animate-pulse ml-1"></span>
    </div>
  );
};

const globalStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&family=Space+Mono&display=swap');
  
  body {
    font-family: 'Orbitron', sans-serif;
  }

  .font-mono {
    font-family: 'Space Mono', monospace;
  }
`;

const Logo = () => (
  <div className="flex items-center space-x-2 p-2">
    <style>{globalStyles}</style>
    <img src="/logo.png" alt="Logo" className="h-6 md:h-8" />
    <span className="text-sm md:text-lg text-white font-semibold">SIMPLYFAI</span>
  </div>
);

const Navigation = ({ activeTab, onTabChange }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useResponsive();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navClasses = `fixed w-full z-50 transition-all duration-300 ${
    isScrolled ? 'bg-sky-900 bg-opacity-90' : 'bg-transparent'
  }`;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={navClasses}>
      <style>{globalStyles}</style>
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center justify-between py-4">
          <Logo />
          {isMobile ? (
            <div>
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none"
              >
                {menuOpen ? 'Close' : 'Menu'}
              </button>
              {menuOpen && (
                <div className="absolute top-full left-0 right-0 bg-sky-900 bg-opacity-90">
                  {tabs.map((tab) => (
                    <a
                      key={tab.id}
                      href={`#${tab.id}`}
                      className={`block px-4 py-2 text-sm ${
                        activeTab === tab.id
                          ? 'text-white bg-sky-700'
                          : 'text-sky-100 hover:bg-sky-700 hover:text-white'
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        onTabChange(tab.id);
                        setMenuOpen(false);
                      }}
                    >
                      {tab.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center space-x-4">
              {tabs.map((tab) => (
                <a
                  key={tab.id}
                  href={`#${tab.id}`}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    activeTab === tab.id
                      ? 'text-white bg-sky-700'
                      : 'text-sky-100 hover:bg-sky-700 hover:text-white'
                  } transition-all duration-300`}
                  onClick={(e) => {
                    e.preventDefault();
                    onTabChange(tab.id);
                  }}
                >
                  {tab.label}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    honeypot: ''
  });
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.honeypot) {
      setSubmitStatus('Error: Form submission failed.');
      return;
    }
    setSubmitStatus('Sending...');
    try {
      // Note: Replace this URL with your actual backend endpoint when deploying
      const response = await fetch('http://localhost:3001/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSubmitStatus('Message sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '', honeypot: '' });
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      setSubmitStatus('Failed to send message. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-lg px-4">
      <div className="mb-6">
        <input 
          className="appearance-none bg-transparent border-b-2 border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 transition-all duration-300" 
          type="text" 
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name" 
          required
        />
      </div>
      <div className="mb-6">
        <input 
          className="appearance-none bg-transparent border-b-2 border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 transition-all duration-300" 
          type="email" 
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email Address" 
          required
        />
      </div>
      <div className="mb-6">
        <input 
          className="appearance-none bg-transparent border-b-2 border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 transition-all duration-300" 
          type="text" 
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Subject" 
          required
        />
      </div>
      <div className="mb-6">
        <textarea 
          className="appearance-none bg-transparent border-b-2 border-gray-300 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500 transition-all duration-300 resize-none" 
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          rows="4"
          required
        ></textarea>
      </div>
      <div className="hidden">
        <input
          type="text"
          name="honeypot"
          value={formData.honeypot}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <button 
          className="w-full md:w-auto bg-transparent hover:bg-white text-white font-semibold hover:text-sky-900 py-2 px-6 border border-white hover:border-transparent rounded-full transition-all duration-300 mb-4 md:mb-0" 
          type="submit"
        >
          Send Message
        </button>
        {submitStatus && <p className="text-white mt-2 md:mt-0">{submitStatus}</p>}
      </div>
    </form>
  );
};

const ContentArea = ({ activeTab }) => {
  const [typedContent, setTypedContent] = useState('');
  const activeContent = tabs.find(tab => tab.id === activeTab);

  useEffect(() => {
    setTypedContent('');
  }, [activeTab]);

  return (
    <main className="flex flex-col items-center justify-start min-h-screen text-center text-white pt-24 md:pt-32 px-4">
      <h1 className="text-3xl md:text-4xl lg:text-6xl font-bold mb-4 md:mb-8 text-white">
        {activeContent.label}
      </h1>
      {activeTab === 'contact-us' ? (
        <div className="w-full max-w-lg">
          <ContactForm />
        </div>
      ) : activeTab === 'challenges' ? (
        <div className="w-full max-w-4xl mx-auto">
          <FuturisticTyping 
            text={activeContent.content} 
            speed={30} 
            onComplete={setTypedContent}
          />
          {typedContent === activeContent.content && activeContent.challenges && (
            <ChallengesSection challenges={activeContent.challenges} />
          )}
        </div>
      ) : (
        <div className="w-full max-w-2xl mx-auto">
          <FuturisticTyping 
            text={activeContent.content} 
            speed={30}
            onComplete={setTypedContent}
          />
          {typedContent === activeContent.content && (
            <a href="#learn-more" className="mt-8 bg-white text-sky-900 font-semibold hover:bg-sky-200 py-2 px-6 rounded-full transition-all duration-300 shadow-lg hover:shadow-xl inline-block">
              Learn more
            </a>
          )}
        </div>
      )}
    </main>
  );
};

const App = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* Background layers (unchanged) */}
      <div className="absolute inset-0 bg-sky-900"></div>
      <div 
        className="absolute inset-0 bg-gradient-to-b from-transparent via-amber-700 to-transparent"
        style={{
          background: 'linear-gradient(180deg, rgba(7,89,133,0) 20%, rgba(217,119,6,1) 50%, rgba(7,89,133,0) 100%)',
        }}
      ></div>
      <div className="absolute inset-0 bg-pink-500 opacity-10"></div>

      {/* Navigation bar */}
      <Navigation activeTab={activeTab} onTabChange={setActiveTab} />
      
      {/* Main content */}
      <div className="relative z-10 pt-16">
        <ContentArea activeTab={activeTab} />
      </div>
    </div>
  );
};

export default App;